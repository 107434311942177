import React from 'react';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
  <>
  <Helmet>
        <title>Get in Touch with Techespark - For Graphic Design, Website Development & Digital Marketing Services</title>
        <meta name="description" content="Contact Techespark for expert graphic design, website development, and digital marketing services. Get in touch today for a free consultation and see how we can help grow your business!" />
        <meta name="keywords" content="Contact Techespark for Graphic Design, Website Development, and Digital Marketing Services" />
      </Helmet>
 <section className="text-gray-600 body-font relative">
<div className="text-gray-600 body-font relative h-[200px] lg:h-[300px] overflow-hidden bg-cover before:content-[''] before:w-full before:h-full before:bg-black before:bg-opacity-70 before:absolute before:top-0 before:left-0">
  <img src={`${process.env.PUBLIC_URL}/about-us-banner.jpg`} width={600} height={600} className='w-full h-full object-cover ' />
  <div className="container px-5 py-24 mx-auto absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
    <h1 className='text-5xl font-bold title-font text-white mb-4 noto-serif-bold  table relative mx-auto before:content-[""] before:w-[70px] before:h-[4px] before:bg-red-400 before:absolute before:bottom-[-30px] before:left-[50%] before:translate-x-[-50%]'>Contact Us</h1>
  </div>
</div>
<div className="text-gray-600 body-font relative">
  <div className="container px-5 py-5 lg:py-24 mx-auto flex  flex-wrap">
  
    <div className="lg:w-1/2 md:w-1/2  overflow-hidden flex items-start justify-start relative">
      
      <div className="bg-white relative flex flex-wrap py-6 text-left">
      <p className="leading-relaxed mb-5 text-gray-500">We'd love to hear from you! Whether you have a question, feedback, or need assistance, feel free to reach out to us using the methods below. Our team is here to help.</p>
        <div className="w-full py-6">
          <h2 className="title-font font-bold text-red-400 text-lg noto-serif-bold">Customer Support</h2>
          <p className="mt-1"><strong>Email :</strong> info@techespark.com</p>
          <p className="mt-1"><strong>Phone :</strong> (+91) 99597 90204</p>
          <p className="mt-1 mb-6"><strong>Support Hours :</strong> Monday to Friday, 9:00 AM - 6:00 PM (Indian Time Zone)</p>
          <h2 className="title-font font-bold text-red-400 text-lg noto-serif-bold">General Inquiries</h2>
          <p className="mt-1 mb-6"><strong>Email :</strong> info@techespark.com</p>
          <h2 className="title-font font-bold text-red-400 text-lg noto-serif-bold">Address</h2>
          <p className="mt-1">H.No. 1-3-50, OmInfra Appartment, Flat No. 203, New Maruthi Nagar, Kothapet Hyderabad - 500035</p>
          <h2 className="title-font font-bold text-red-400 text-lg mt-4 noto-serif-bold">Follow Us on Social Media</h2>
          <p><span className="inline-flex sm:ml-auto sm:mt-6 mt-2 justify-center sm:justify-start">
        <a href="https://www.facebook.com/people/techespark/61565947036491/" translate='_blank' className="text-gray-500 hover:text-red-400">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a href="https://x.com/Techespark" translate='_blank' className="ml-3 text-gray-500 hover:text-red-400">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a href="https://www.linkedin.com/company/techespark" translate='_blank' className="ml-3 text-gray-500 hover:text-red-400">
          <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" className="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span></p>
        </div>
      </div>
    </div>
    <div className="lg:w-1/2 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 text-left lg:pl-10">
      
      {/* <div className="relative mb-4">
        <label for="name" className="leading-7 text-sm text-gray-600">Name</label>
        <input type="text" id="name" name="name" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div className="relative mb-4">
        <label for="email" className="leading-7 text-sm text-gray-600">Email</label>
        <input type="email" id="email" name="email" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div className="relative mb-4">
        <label for="subject" className="leading-7 text-sm text-gray-600">Subject</label>
        <input type="text" id="email" name="subject" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div className="relative mb-4">
        <label for="message" className="leading-7 text-sm text-gray-600">Message</label>
        <textarea id="message" name="message" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
      </div>
      <button className="text-white bg-red-400 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 font-bold rounded text-lg">Submit</button> */}
    <ContactForm/>

    </div>
    <div className='w-full container mx-auto mt-10 border-[10px] border-gray-200'>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.7678961443817!2d78.54265269697758!3d17.37489816033765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99ac1d7068e7%3A0x4f0d046f3aa9c3f1!2sOm%20infra%20apartment!5e0!3m2!1sen!2sin!4v1727813185453!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
</div>
</section>
</>  

  );
};

export default Contact;