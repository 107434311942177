import React from 'react';
import { MdOutlineDesignServices } from "react-icons/md";
import { IoCodeSlash } from "react-icons/io5";
import { IoRocketOutline } from "react-icons/io5";
import { Helmet } from 'react-helmet';
const GraphicDesign = () => {
  return (
    <>
     <Helmet>
        <title>Graphic Designing - Professional Graphic Design Services | Logo, Branding, & Print Design | UX/UI Design Services | User-Centered Web & Mobile Experiences</title>
        <meta name="description" content="Transform your brand with our professional graphic design services. From logos to print media, we create visually stunning designs that captivate and engage. Let us bring your vision to life!. Improve user experience and engagement with our UX/UI design services. We create intuitive, user-friendly interfaces for websites and mobile apps that deliver exceptional user experiences" />
        <meta name="keywords" content="graphic design services, logo design, branding design, print design, visual design, creative graphics, professional graphic design, brand identity, UX design, UI design, user experience design, user interface design, website UX, mobile app UX, web design, interaction design, user-centered design
" />
      </Helmet>
  <section className="text-gray-600 body-font">
    <div className="text-gray-600 body-font relative h-[200px] lg:h-[300px] overflow-hidden bg-cover before:content-[''] before:w-full before:h-full before:bg-black before:bg-opacity-70 before:absolute before:top-0 before:left-0">
  <img src={`${process.env.PUBLIC_URL}/about-us-banner.jpg`} width={600} height={600} className='w-full h-full object-cover ' title='about us banner' />
  <div className="container px-5 py-24 mx-auto absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
    <h1 className='text-4xl lg:text-5xl font-bold title-font text-white mb-4 noto-serif-bold  table relative mx-auto before:content-[""] before:w-[70px] before:h-[4px] before:bg-red-400 before:absolute before:bottom-[-30px] before:left-[50%] before:translate-x-[-50%]'>Graphic Design Service</h1>
  </div>
</div>
  <div className="container px-5 py-6 lg:py-24 mx-auto">
    <div className="text-center mb-10 lg:mb-20">
      <p className="text-base leading-relaxed mx-auto text-black-500 text-left">At Techespark, we bring ideas to life through stunning visuals and impactful designs. Our graphic design services are tailored to help businesses communicate their message, captivate their audience, and elevate their brand. From concept to creation, we focus on delivering designs that not only look amazing but also drive results</p>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mt-4 md:space-y-0 space-y-6  lg:mb-20">
    <div className="p-4 w-full flex flex-col text-center items-center">
      <img src={`${process.env.PUBLIC_URL}/graphic-design-banner.jpg`} width={600} height={600} className='w-full h-full object-cover ' title="Techespark Graphic Design Service" />
      </div>
      <div className="p-4 w-full flex flex-col text-left items-center justify-start">
          <h2 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">Our Graphic Design Services Include</h2>
          <ul>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Brand Identity & Logo Design :</strong> Your brand’s identity is the foundation of your business. We specialize in creating unique and memorable logos, brand guidelines, and identity systems that reflect your vision and resonate with your target audience. Whether you’re starting a new brand or refreshing an existing one, we deliver designs that capture your essence.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Marketing Collateral Design :</strong> First impressions matter. Our team creates high-quality, professional marketing materials that leave a lasting impact. From brochures, flyers, and posters to business cards and presentations, we ensure your materials are consistent with your brand and designed to engage your audience effectively.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Website Graphics & UI Design :</strong> Great web design goes hand in hand with compelling graphics. We create web visuals, banners, icons, and user interface (UI) elements that enhance user experience while staying aligned with your brand aesthetics. We ensure that every graphic is optimized for performance, responsiveness, and impact.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Social Media & Digital Graphics :</strong> In a digital-first world, it’s crucial to stand out on social platforms. We design eye-catching social media graphics, ads, and banners that boost engagement and increase visibility. Our custom designs are tailored for platforms like Instagram, Facebook, LinkedIn, and more, ensuring your content shines in every feed.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Infographics & Data Visualization :</strong> Transform complex data into visually engaging infographics that tell a story. We specialize in simplifying information through clear and creative visuals, making it easy for your audience to understand and absorb important insights. Infographics are perfect for reports, blogs, presentations, and social media campaigns.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Packaging Design :</strong> The right packaging can make all the difference in capturing a customer’s attention. We design product packaging that not only looks attractive on the shelf but also conveys the essence of your brand. Whether it’s sleek, modern packaging or something more playful, we ensure your product gets noticed.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Print Design Services :</strong> From magazines to billboards, our print design expertise spans across all formats. We combine creativity with attention to detail, ensuring that every print project meets the highest quality standards. Whether you need large-scale prints, signage, or detailed illustrations, we’ve got you covered.</li>
          </ul>
      </div>
      
    </div>
    
    <div className="flex flex-wrap sm:-m-4 -mx-4 mb-10 lg:mb-20 -mt-4 md:space-y-0 space-y-6">
      <div className="p-4 lg:w-1/2 flex flex-col text-center items-center order-1 lg:order-none">
      <h2 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">Our Design Process</h2>
      <ul>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Consultation & Concept Development :</strong> We start by understanding your brand, goals, and vision. Based on your input, we develop initial concepts and creative directions that align with your business objectives.
          </li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Design Creation & Feedback :</strong> Once we have a clear direction, our designers work on creating the visuals. We collaborate closely with you, incorporating feedback at every stage to ensure the final design is perfect.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Final Delivery & Support :</strong> After final approval, we deliver high-quality files in the formats you need. Whether for web, print, or social media, we ensure the designs are optimized for their respective platforms.</li>
        </ul>
      </div>
      <div className="p-4 lg:w-1/2 flex flex-col text-left items-center justify-start">
      <h2 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">Why Choose Us?</h2>
      <ul>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Creative Expertise :</strong> Our team of experienced graphic designers brings creativity, attention to detail, and industry knowledge to every project.
          </li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Tailored Solutions :</strong> No two businesses are the same. We craft unique designs that are tailored to your specific needs and goals.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Consistency & Quality :</strong> We ensure that every design is consistent with your brand identity and meets the highest standards of quality.</li>
        </ul>
      </div>
      
    </div>

   

    
    <p className="leading-relaxed text-base text-left mb-[20px] mt-10">At Techespark, we don’t just design—we create experiences that connect with your audience and elevate your brand. Let us help you make your mark with powerful and engaging graphic design.</p>
  </div>
</section>
</>
  );
};

export default GraphicDesign;