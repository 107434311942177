import React from 'react';
import Hero from './Hero/hero';
import AboutHome from './About/AboutHome';
import ServicesHome from './Services/ServicesHome';
import Testimonials from './Testimonials/testimonials';
import PortfolioHome from './Portfolio/PorfolioHome';
const Home = () => {
  return (
    <>
     <Hero/>
     <AboutHome />
     <ServicesHome/>
     <PortfolioHome/>
     <Testimonials />
    </>
  );
};

export default Home;