import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './components/layout/Home/Home';
import About from './components/layout/Home/About/About';
import Contact from './components/layout/Home/Contact/Contact';
import Services from './components/layout/Home/Services/Services';
import Portfolio from './components/layout/Home/Portfolio/Portfolio';
import WebsiteDesign from './components/layout/Home/Services/WebsiteDesign';
import GraphicDesign from './components/layout/Home/Services/GraphicDesign';
import DigitalMarketing from './components/layout/Home/Services/DigitalMarketing';
import EcommerceDevelopment from './components/layout/Home/Services/E-CommerceDevelopment';
function App() {
  return (
    <div className="App">
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services/website-design-development" element={<WebsiteDesign />} />
        <Route path="/services/graphic-design-service" element={<GraphicDesign />} />
        <Route path="/services/digital-marketing-service" element={<DigitalMarketing />} />
        <Route path="/services/ecommerce-development-service" element={<EcommerceDevelopment/>} />
        
      </Routes>
    </div>
  );
}

export default App;
