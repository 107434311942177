// ContactForm.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile:'',
        message: ''
    });

    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_8kavsor', 'template_h7sgfg2', formData, 'hXVAbsJg0Cokshj9O')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setStatus('Email sent successfully!');
                // Reset form data after sending
                setFormData({ name: '', email: '', message: '' });
            }, (error) => {
                console.log('FAILED...', error);
                setStatus('Failed to send email. Please try again later.');
            });
    };

    return (
        <div>
            <h2 className='text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full'>Get in Touch With Us!</h2>
            <form onSubmit={handleSubmit}>
              <div className='relative mb-5'>
                <label className='mb-1 table'>Name <em className='text-red-400'>*</em></label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className='w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
                    />
                </div>
                <div className='relative mb-5'>
                <label className='mb-1 table'>Email <em className='text-red-400'>*</em></label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className='w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
                    />
                </div>
                <div className='relative mb-5'>
                <label className='mb-1 table'>Email <em className='text-red-400'>*</em></label>
                    <input
                        type="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                        className='w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
                    />
                </div>
                <div className='relative mb-5'>
                <label className='mb-1 table'>Message <em className='text-red-400'>*</em></label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        className='w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
                    />
                </div>
                <button type="submit" className='bg-red-400 h-[40px] rounded-[6px] w-full hover:bg-red-500 text-white font-bold text-lg px-4'>Send Email</button>
            </form>
            {status && <p className={`${status === 'error' ? 'text-red-600 fon-bold text-[18px]' : 'text-green-600 fon-bold text-[18px]'}`}>{status}</p>}
        </div>
    );
};

export default ContactForm;
