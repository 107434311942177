import React from 'react';
import { FaUserCircle } from "react-icons/fa";
const Testimonials = () => {
  return (
<section className="text-gray-600 body-font bg-[#f7f7f7]">
  <div className="container px-5 py-10 lg:py-24 mx-auto">
    <h1 className="text-3xl lg:text-4xl font-bold title-font text-gray-500 mb-12 text-center noto-serif-bold">Client Testimonials</h1>
    <div className="flex flex-wrap -m-4">
      <div className="p-4 md:w-1/3 w-full">
        <div className="h-full bg-white p-8 rounded border border-gray-200">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-red-300 mb-4" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <h3 className='title-font font-bold text-xl mb-2 text-gray-500'>Exceptional Design and User Experience</h3>
          <p className="leading-relaxed mb-6">Working with [Your Company Name] on our brand refresh and website redesign was a fantastic experience. Their team understood our vision and brought it to life with a sleek, modern design that resonates with our audience. The new website has improved our user experience and increased our online engagement. We’ve received so much positive feedback, and the process was smooth from start to finish.</p>
          <a className="inline-flex items-center">
          <FaUserCircle  size={40} className='text-gray-300'/>
            <span className="flex-grow flex flex-col pl-4">
              <span className="title-font font-bold text-red-400">Sarah K</span>
              <span className="text-gray-500 text-sm">CEO</span>
            </span>
          </a>
        </div>
      </div>
      <div className="p-4 md:w-1/3 w-full">
        <div className="h-full bg-white p-8 rounded border border-gray-200">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-red-300 mb-4" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <h3 className='title-font font-bold text-xl mb-2 text-gray-500'>Highly Professional and Results-Driven</h3>
          <p className="leading-relaxed mb-6">The team at [Your Company Name] helped us create a digital marketing strategy that transformed our online presence. Their expertise in SEO and PPC advertising generated incredible results in just a few months. We saw a significant increase in website traffic and conversions, which has had a huge impact on our bottom line. We couldn’t be happier with the partnership!</p>
          <a className="inline-flex items-center">
          <FaUserCircle  size={40} className='text-gray-300'/>
            <span className="flex-grow flex flex-col pl-4">
              <span className="title-font font-bold text-red-400">David L</span>
              <span className="text-gray-500 text-sm">Marketing Director</span>
            </span>
          </a>
        </div>
      </div>
      <div className="p-4 md:w-1/3 w-full">
        <div className="h-full bg-white p-8 rounded border border-gray-200">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-red-300 mb-4" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <h3 className='title-font font-bold text-xl mb-2 text-gray-500'>Creative Solutions and Flawless Execution</h3>
          <p className="leading-relaxed mb-6">We partnered with [Your Company Name] for our graphic design and website development needs, and we’re thrilled with the outcome. The team’s creativity and attention to detail resulted in a visually stunning website that perfectly reflects our brand. Their support and responsiveness throughout the process were top-notch. We highly recommend their services to anyone looking to elevate their brand.</p>
          <a className="inline-flex items-center">
          <FaUserCircle  size={40} className='text-gray-300'/>
            <span className="flex-grow flex flex-col pl-4">
              <span className="title-font font-bold text-red-400">Melissa T</span>
              <span className="text-gray-500 text-sm">Founder</span>
            </span>
          </a>
        </div>
      </div>
      <div className="p-4 md:w-1/3 w-full">
        <div className="h-full bg-white p-8 rounded border border-gray-200">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-red-300 mb-4" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <h3 className='title-font font-bold text-xl mb-2 text-gray-500'>A True Digital Marketing Partner</h3>
          <p className="leading-relaxed mb-6">Techespark has been instrumental in growing our social media presence and executing our digital marketing campaigns. Their team is highly knowledgeable and always stays ahead of industry trends. Thanks to their content marketing strategies and targeted social media ads, we’ve seen a marked increase in brand awareness and customer engagement. They truly feel like an extension of our marketing team.</p>
          <a className="inline-flex items-center">
          <FaUserCircle  size={40} className='text-gray-300'/>
            <span className="flex-grow flex flex-col pl-4">
              <span className="title-font font-bold text-red-400">John P</span>
              <span className="text-gray-500 text-sm">COO</span>
            </span>
          </a>
        </div>
      </div>
      <div className="p-4 md:w-1/3 w-full">
        <div className="h-full bg-white p-8 rounded border border-gray-200">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-red-300 mb-4" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <h3 className='title-font font-bold text-xl mb-2 text-gray-500'>Outstanding E-Commerce Development</h3>
          <p className="leading-relaxed mb-6">We needed a custom e-commerce website, and [Your Company Name] exceeded our expectations. From the initial planning stages to launch, they delivered on every promise. The website is fast, responsive, and easy to manage, which has been a game-changer for our business. Sales have skyrocketed since the launch, and we couldn’t be more grateful for their expertise.</p>
          <a className="inline-flex items-center">
          <FaUserCircle  size={40} className='text-gray-300'/>
            <span className="flex-grow flex flex-col pl-4">
              <span className="title-font font-bold text-red-400">Emily R</span>
              <span className="text-gray-500 text-sm">E-Commerce Manager</span>
            </span>
          </a>
        </div>
      </div>
      <div className="p-4 md:w-1/3 w-full">
        <div className="h-full bg-white p-8 rounded border border-gray-200">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-red-300 mb-4" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <h3 className='title-font font-bold text-xl mb-2 text-gray-500'>Expert Guidance and Long-Term Success</h3>
          <p className="leading-relaxed mb-6">After struggling with various marketing agencies, we found [Your Company Name], and they have been a game-changer for us. Their team didn’t just offer solutions; they educated us about the process, provided clear strategies, and continuously optimized our campaigns. We’ve seen steady growth in website traffic, lead generation, and brand visibility, all thanks to their expertise.</p>
          <a className="inline-flex items-center">
          <FaUserCircle  size={40} className='text-gray-300'/>
            <span className="flex-grow flex flex-col pl-4">
              <span className="title-font font-bold text-red-400">Michael B</span>
              <span className="text-gray-500 text-sm">Owner</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
  );
};

export default Testimonials;