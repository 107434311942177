import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

const RequestQuoteForm = () => {
  const [statusMessage, setStatusMessage] = useState('');  // To display success or error messages
  const [isSubmitted, setIsSubmitted] = useState(false);   // Track whether form has been submitted
  const form = useRef(); // Reference to the form

  const sendQuoteRequest = (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Send email using EmailJS
    emailjs.sendForm(
      'service_8kavsor',   // Replace with your EmailJS service ID
      'template_ejrp1dj',  // Replace with your EmailJS template ID
      form.current,
      'hXVAbsJg0Cokshj9O'    // Replace with your EmailJS public key
    )
    .then((result) => {
      console.log(result.text);
      setStatusMessage('Quote request sent successfully!');  // Success message
      setIsSubmitted(true);  // Set form as submitted
      form.current.reset();  // Reset the form fields
    }, (error) => {
      console.log(error.text);
      setStatusMessage('Failed to send quote request. Please try again.');  // Error message
    });
  };

  return (
    <div>
      {/* <h2 className='text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full'>Request a Quote</h2> */}

      {/* Step 1: Conditionally render the form or success message */}
      {isSubmitted ? (
        <div>
          <h3>{statusMessage}</h3>
          <p>Thank you for your request! We will contact you with your quote shortly.</p>
        </div>
      ) : (
        <form ref={form} onSubmit={sendQuoteRequest}>
           <div className='relative mb-5'> 
            <label className='mb-1 table'>Name <em className='text-red-400'>*</em></label>
            <input type="text" name="user_name" placeholder="Your Name" required  className='w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'/>
            </div>
          <div className='relative mb-5'>
            <label className='mb-1 table'>Email <em className='text-red-400'>*</em></label>
            <input type="email" name="user_email" placeholder="Your Email" required  className='w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'/>
          </div>
          <div className='relative mb-5'>
            <label className='mb-1 table'>Phone <em className='text-red-400'>*</em></label>
            <input type="tel" name="user_phone" placeholder="Your Phone Number" required className='w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out' />
          </div>
          <div className='relative mb-5'>
            <label className='mb-1 table'>Company <em className='text-red-400'>*</em></label>
            <input type="text" name="user_company" placeholder="Your Company" required className='w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'/>
          </div>
          <div className='relative mb-5'>
            <label className='mb-1 table'>Quote Details <em className='text-red-400'>*</em></label>
            <textarea name="quote_details" placeholder="Describe your project and requirements..." required className='w-full  bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out h-[100px]'></textarea>
          </div>
          <button type="submit" className='w-full h-[45px] bg-red-400 hover:bg-red-600 text-white text-[18px] font-bold rounded-md'>Request a Quote</button>
        </form>
      )}

      {/* Step 2: Display the status message (error or success) */}
      {statusMessage && !isSubmitted && <p>{statusMessage}</p>}
    </div>
  );
};

export default RequestQuoteForm;
