import React, { useState } from 'react';
import { MdOutlineDesignServices } from "react-icons/md";
import { IoCodeSlash } from "react-icons/io5";
import { IoRocketOutline } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
import RequestQuoteForm from '../RequestAQuote/RequestQuote';

const ServicesHome = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <>
    <section className="text-gray-600 body-font bg-[#f7f7f7] body-font">
    <div className="container px-5 py-10 lg:py-24 mx-auto">
      <div className="text-center mb-20">
        <h1 className="text-3xl lg:text-4xl  font-bold title-font  text-black-600 mb-4 noto-serif-bold">Our Services</h1>
        <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-black-500">At Techespark, we offer a comprehensive suite of creative and strategic services designed to help your business thrive in the digital world. From stunning graphic design and high-performance websites to powerful digital marketing campaigns, we provide end-to-end solutions that elevate your brand, engage your audience, and drive results. Our goal is to be your trusted partner in crafting and executing a digital presence that fuels growth and success.</p>
        <div className="flex mt-6 justify-center">
          <div className="w-16 h-1 rounded-full bg-red-400 inline-flex"></div>
        </div>
      </div>
      <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
        <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
          <div className="w-20 h-20 inline-flex items-center justify-center font-medium rounded-full bg-red-100 text-red-400 mb-5 flex-shrink-0">
          <MdOutlineDesignServices size={50} />
          </div>
          <div className="flex-grow">
            <h2 className="text-gray-600 text-2xl title-font font-bold mb-3 noto-serif-bold">Graphic Design</h2>
            <p className="leading-relaxed text-base text-gray-600">Visual communication is at the heart of brand success. Our graphic design services combine creativity and strategy to create eye-catching visuals that resonate with your audience and enhance your brand identity.</p>
            <button type='button' onClick={togglePopup} className='mx-auto mt-5 font-base font-semibold text-red-400 flex gap-2 items-center underline'>Request a Quote <FaArrowRightLong /></button>
          </div>
        </div>
        <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
          <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-red-100 text-red-400 mb-5 flex-shrink-0">
            <IoCodeSlash size={50}/>
          </div>
          <div className="flex-grow">
            <h2 className="text-gray-600 text-2xl title-font font-bold mb-3 noto-serif-bold">Website Design & Development</h2>
            <p className="leading-relaxed text-base text-gray-600">Your website is often the first interaction potential customers have with your brand. We design and develop custom websites that are not only visually stunning but also optimized for user experience, performance, and growth.</p>
            <button type='button' onClick={togglePopup} className='mx-auto mt-5 font-base font-semibold text-red-400 flex gap-2 items-center underline'>Request a Quote <FaArrowRightLong /></button>
          </div>
        </div>
        <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
          <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-red-100 text-red-400 mb-5 flex-shrink-0">
            <IoRocketOutline size={50}/>
          </div>
          <div className="flex-grow">
            <h2 className="text-gray-600 text-2xl title-font font-bold mb-3 noto-serif-bold">Digital Marketing</h2>
            <p className="leading-relaxed text-base text-gray-600">Building a website and having great visuals is just the beginning. Our digital marketing services are designed to increase your brand’s visibility, drive traffic, and turn leads into loyal customers through data-driven strategies.</p>
            <button type='button' onClick={togglePopup} className='mx-auto mt-5 font-base font-semibold text-red-400 flex gap-2 items-center underline'>Request a Quote <FaArrowRightLong /></button>
          </div>
        </div>
      </div>
      <a href='/services' className="flex mx-auto mt-16 text-red-400 w-[188px] font-bold rounded-[25px] border-[2px] border-red-400 items-center py-2 px-8 focus:outline-none hover:bg-red-400 hover:text-white text-lg noto-serif-bold">Read More
      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
      </svg>
      </a>
    </div>
  </section>
{isPopupVisible && (
  <div className='w-full h-full fixed bg-black/80 left-0 top-0 flex items-center justify-center z-[999]'>
    <div className='w-[500px] max-w-[95%] bg-white rounded-lg'>
      <div className='px-10 py-5 border-b border-gray-200 flex items-center justify-between'>
      <h2 className='text-red-400 text-2xl title-font font-bold text-left noto-serif-bold w-full'>Request a Quote</h2>
        <button onClick={togglePopup} className='text-black/60'><IoCloseSharp size={20}/></button>
      </div>
      <div className='py-10 px-10'>
        <RequestQuoteForm/>
      </div>
    </div>
  </div>
)}
</>
  );
};

export default ServicesHome;