import React from 'react';
const AboutHome = () => {
  return (
<section className="text-gray-600 manrope-regular body-font">
  <div className="container px-5  py-10 lg:py-24 mx-auto">
    <div className="text-center mb-10 lg:mb-20">
      <h1 className="text-3xl  lg:text-4xl font-bold title-font text-black-600 mb-4 noto-serif-bold">About Our Techespark</h1>
      <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-black-600">At Techespark, we are passionate about transforming businesses through creative design, cutting-edge web development, and powerful digital marketing strategies. We are a full-service digital agency, dedicated to helping brands establish a dynamic online presence and drive measurable results.</p>
      <div className="flex mt-6 justify-center">
        <div className="w-16 h-1 rounded-full bg-red-400 inline-flex"></div>
      </div>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
      <div className="p-4 md:w-1/2 flex flex-col text-left items-center">
        <div className="w-full">
          <h1 className="text-black-600 text-2xl title-font font-bold mb-5 text-left noto-serif-bold">Why Choose Us?</h1>
          <p className="leading-relaxed text-base text-left mb-[20px]"><strong>Experienced Team</strong> : Our team of developers, designers, and strategists have years of experience building successful mobile apps across various industries.
          </p>
          <p className="leading-relaxed text-base text-left mb-[20px]"><strong>End-to-End Solutions</strong> : From concept and design to development and deployment, we provide comprehensive mobile app solutions that cover every stage of the app lifecycle.</p>
          <p className="leading-relaxed text-base text-left mb-[20px]"><strong>Scalable & Secure</strong> : We focus on building apps that are both scalable to accommodate business growth and secure to protect sensitive data and user privacy.</p>
          <p className="leading-relaxed text-base text-left mb-[20px]"><strong>User-Centric Approach</strong> : We prioritize the user experience at every step of the development process, ensuring that your app is engaging, intuitive, and easy to use.</p>          
          <a href='/about' className="mt-3 text-red-400 inline-flex font-bold   justify-start self-start items-center border-[2px] border-red-400 p-3 px-8 rounded-[25px] hover:bg-red-400 hover:text-white transition-all ease-in-out noto-serif-bold">Read More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </div>
      <div className="p-4 md:w-1/2 flex flex-col text-center items-center overflow-hidden">
        <img src={`${process.env.PUBLIC_URL}/About-us-home-banner.jpg`} width={600} height={600} className='w-full h-auto hover:scale-110 transition-all ease-in-out' />
      </div>
    </div>
  </div>
</section>
);
};

export default AboutHome;