import React from 'react';
import { MdOutlineDesignServices } from "react-icons/md";
import { IoCodeSlash } from "react-icons/io5";
import { IoRocketOutline } from "react-icons/io5";
import { Helmet } from 'react-helmet';
const DigitalMarketing = () => {
  return (
    <>
    <Helmet>
        <title>Digital Marketing - Expert Digital Marketing Services | SEO, PPC, Social Media & More</title>
        <meta name="description" content="Grow your business with our expert digital marketing services. From SEO and PPC to social media and content marketing, we craft data-driven strategies to boost your online visibility and ROI." />
        <meta name="keywords" content="digital marketing, SEO services, PPC management, social media marketing, content marketing, digital advertising, email marketing, online marketing, digital marketing agency" />
      </Helmet>
      <section className="text-gray-600 body-font">
    <div className="text-gray-600 body-font relative h-[200px] lg:h-[300px] overflow-hidden bg-cover before:content-[''] before:w-full before:h-full before:bg-black before:bg-opacity-70 before:absolute before:top-0 before:left-0">
  <img src={`${process.env.PUBLIC_URL}/about-us-banner.jpg`} width={600} height={600} className='w-full h-full object-cover ' title='about us banner' />
  <div className="container px-5 py-24 mx-auto absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
    <h1 className='text-4xl lg:text-5xl font-bold title-font text-white mb-4 noto-serif-bold  table relative mx-auto before:content-[""] before:w-[70px] before:h-[4px] before:bg-red-400 before:absolute before:bottom-[-30px] before:left-[50%] before:translate-x-[-50%]'>Digital Marketing Services</h1>
  </div>
</div>
  <div className="container px-5 py-6 lg:py-24 mx-auto">
    <div className="text-center mb-10 lg:mb-20">
      <p className="text-base leading-relaxed mx-auto text-black-500 text-left">At Techespark, we help businesses grow by leveraging the power of digital marketing. In today’s fast-paced online world, having a strategic, data-driven digital presence is essential. Our comprehensive digital marketing services are designed to drive traffic, increase conversions, and elevate your brand. Whether you're looking to enhance your online visibility or engage new audiences, we provide tailored solutions that deliver measurable results.</p>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mt-4 md:space-y-0 space-y-6  lg:mb-20">
    <div className="p-4 w-full flex flex-col text-center items-center">
    <img src={`${process.env.PUBLIC_URL}/digital-marketing-banner.jpg`} width={600} height={600} className='w-full h-full object-cover' title="Techespark Digital Marketing Service" />
      </div>
      <div className="p-4 w-full flex flex-col text-left items-center justify-start">
          <h2 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">Our Digital Marketing Services Include</h2>
          <ul>
             <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Search Engine Optimization (SEO) :</strong> Be found where it matters most—on search engines. Our SEO experts use the latest techniques to optimize your website for higher rankings on Google, Bing, and other search engines. From keyword research to on-page optimization and link building, we ensure your website attracts relevant traffic and converts visitors into customers.</li>
             <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Pay-Per-Click (PPC) Advertising :</strong> Target your ideal customers with precision through paid ads. We design, manage, and optimize PPC campaigns across platforms like Google Ads, Bing Ads, and social media networks to maximize your return on investment (ROI). Whether it’s search ads, display ads, or remarketing campaigns, we focus on driving high-quality leads to your business.</li>
             <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Social Media Marketing :</strong> Build and nurture relationships with your audience on the platforms they use most. Our social media marketing services help you create engaging content, grow your following, and drive brand awareness across platforms like Facebook, Instagram, LinkedIn, Twitter, and more. We craft and execute social media strategies that foster community engagement and encourage conversions</li>
             <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Content Marketing :</strong> Content is the backbone of effective digital marketing. Our content marketing services focus on creating valuable, relevant, and consistent content that attracts and engages your target audience. From blog posts and articles to videos, infographics, and eBooks, we develop content that drives traffic, enhances SEO, and positions you as an industry leader.</li>
             <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Email Marketing :</strong> Keep your customers engaged and informed with personalized email campaigns that drive results. Our email marketing services include strategy development, template design, automation setup, and analytics. We focus on building segmented lists and creating targeted campaigns that deliver high open rates and conversions.</li>
             <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Conversion Rate Optimization (CRO) :</strong> Turning visitors into customers is key to your online success. Our CRO services analyze user behavior and site performance to optimize landing pages, call-to-action buttons, and site layout to increase your conversion rate. We use A/B testing, heatmaps, and analytics tools to identify opportunities for improvement and maximize ROI.</li>
             <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Influencer Marketing :</strong> Harness the power of influencers to expand your reach. We connect your brand with the right influencers in your niche to promote your products or services in a way that resonates with their audience. By building authentic partnerships with influencers, we help you increase credibility and drive engagement.</li>
             <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Online Reputation Management (ORM) :</strong> Your brand’s reputation can make or break your business. Our ORM services help you manage and protect your online image by monitoring reviews, addressing negative feedback, and promoting positive content. We work to ensure that your business remains trustworthy and reputable in the eyes of your audience.</li>
             <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Analytics & Reporting :</strong> Data is at the core of every successful digital marketing campaign. We provide detailed analytics and reporting to track the performance of your campaigns and identify areas for improvement. Our team uses industry-leading tools to analyze traffic, conversions, and customer behavior, helping you make informed decisions and drive continuous growth.</li>
          </ul>
      </div> 
    </div>
    
    <div className="flex flex-wrap sm:-m-4 -mx-4 mb-10 lg:mb-20 -mt-4 md:space-y-0 space-y-6">
      <div className="p-4 lg:w-1/2 flex flex-col text-center items-center lg:order-none">
      <h2 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">Our Process</h2>
          <ul>
              <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Discovery & Strategy :</strong> We start by understanding your business goals, target audience, and competitive landscape. Based on this, we develop a tailored digital marketing strategy designed to meet your specific needs.</li>
              <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Execution & Management :</strong> Once the strategy is in place, we execute campaigns across multiple channels, ensuring that all efforts are aligned and optimized for maximum impact.</li>
              <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Optimization & Growth :</strong> As campaigns run, we continually monitor performance and optimize tactics to improve results. We provide transparent reporting and ongoing support to ensure your business continues to grow.</li>
          </ul>
      </div>
      <div className="p-4 lg:w-1/2 flex flex-col text-left items-center justify-start">
      <h2 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">Why Choose Techespark?</h2>
      <ul>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Results-Driven Approach :</strong> We focus on delivering measurable results that align with your business objectives. Every strategy is designed to drive ROI and growth.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Full-Service Solutions :</strong> From SEO and PPC to content creation and social media management, we offer a full suite of digital marketing services under one roof.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Customized Strategies :</strong> No cookie-cutter solutions here. We create tailored marketing strategies based on your industry, goals, and audience to ensure maximum effectiveness.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Expert Team :</strong> Our team of digital marketing specialists stays on top of the latest trends and technologies, ensuring that your campaigns are always cutting-edge and relevant.</li>        
          </ul>
      </div>
      
    </div>

    <p className="leading-relaxed text-base text-left mb-[20px] mt-10">At Techespark, we’re passionate about helping businesses succeed in the digital space. Let us be your partner in growth and take your brand to new heights with our digital marketing expertise.</p>
  </div>
</section>   
 </>

  );
};

export default DigitalMarketing;