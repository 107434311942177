import React from 'react';
import { MdOutlineDesignServices } from "react-icons/md";
import { IoCodeSlash } from "react-icons/io5";
import { IoRocketOutline } from "react-icons/io5";
import { Helmet } from 'react-helmet';
const Services = () => {
  return (
    <>
    <Helmet>
        <title>Our Services - Graphic Design, Website Development & Digital Marketing Services | Techespark
        </title>
        <meta name="description" content="Techespark offers expert graphic design, website development, and digital marketing services to help businesses succeed online. Contact us today for customized creative and digital solutions!" />
        <meta name="keywords" content="Professional Graphic Design, Website Development, and Digital Marketing Services" />
      </Helmet>

  <section className="text-gray-600 body-font">
    <div className="text-gray-600 body-font relative h-[200px] lg:h-[300px] overflow-hidden bg-cover before:content-[''] before:w-full before:h-full before:bg-black before:bg-opacity-70 before:absolute before:top-0 before:left-0">
  <img src={`${process.env.PUBLIC_URL}/about-us-banner.jpg`} width={600} height={600} className='w-full h-full object-cover ' title='about us banner' />
  <div className="container px-5 py-24 mx-auto absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
    <h1 className='text-4xl lg:text-5xl font-bold title-font text-white mb-4 noto-serif-bold  table relative mx-auto before:content-[""] before:w-[70px] before:h-[4px] before:bg-red-400 before:absolute before:bottom-[-30px] before:left-[50%] before:translate-x-[-50%]'>Our Services</h1>
  </div>
</div>
  <div className="container px-5 py-6 lg:py-24 mx-auto">
    <div className="text-center mb-10 lg:mb-20">
      <p className="text-base leading-relaxed mx-auto text-black-500 text-left">At Techespark, we offer a comprehensive suite of creative and strategic services designed to help your business thrive in the digital world. From stunning graphic design and high-performance websites to powerful digital marketing campaigns, we provide end-to-end solutions that elevate your brand, engage your audience, and drive results. Our goal is to be your trusted partner in crafting and executing a digital presence that fuels growth and success.</p>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mt-4 md:space-y-0 space-y-6  lg:mb-20">
      <div className="p-4 lg:w-1/2 flex flex-col text-left items-center justify-start">
          <h1 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">Graphic Design</h1>
          <p className="leading-relaxed text-base text-left mb-[20px]">Visual communication is at the heart of brand success. Our <strong>graphic design services</strong> combine creativity and strategy to create eye-catching visuals that resonate with your audience and enhance your brand identity</p>
          <ul>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Logo & Brand Identity Design</strong><br/> We develop distinctive logos and cohesive brand identity systems that reflect your vision and position your business for success.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Marketing Collateral</strong><br/> From brochures, business cards, and posters to digital ads and presentations, we create professional marketing materials that amplify your message.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Social Media Graphics</strong><br/> Boost your online presence with engaging and visually compelling social media content that captures attention and drives interaction.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Custom Illustrations & Infographics</strong><br/> Simplify complex information with creative illustrations and infographics designed to communicate your story with clarity and style.</li>          
          </ul>
          <a href="/graphic-design-service"  className='mr-auto text-red-400 border border-red-400 px-5 py-2 mt-5 font-base font-semibold rounded-[25px] hover:bg-red-400 hover:text-white text-lg noto-serif-bold'>Read More</a>
      </div>
      <div className="p-4 lg:w-1/2 flex flex-col text-center items-center">
      <img src={`${process.env.PUBLIC_URL}/graphic-design-banner.jpg`} width={600} height={600} className='w-full h-full object-cover ' title="Techespark Graphic Design Service" />
      </div>
    </div>
    
    <div className="flex flex-wrap sm:-m-4 -mx-4 mb-10 lg:mb-20 -mt-4 md:space-y-0 space-y-6">
      <div className="p-4 lg:w-1/2 flex flex-col text-center items-center order-1 lg:order-none">
      <img src={`${process.env.PUBLIC_URL}/web-design-banner.jpg`} width={600} height={600} className='w-full h-full object-cover ' title="Techespark Web Design Service" />
      </div>
      <div className="p-4 lg:w-1/2 flex flex-col text-left items-center justify-start">
          <h1 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">Website Design & Development</h1>
          <p className="leading-relaxed text-base text-left mb-[20px]">Your website is often the first interaction potential customers have with your brand. We design and develop <strong>custom websites</strong> that are not only visually stunning but also optimized for user experience, performance, and growth.</p>
          <ul>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Custom Website Design</strong><br/> Our designs are tailor-made to match your brand’s personality and your business goals. Whether it’s a sleek corporate site or an e-commerce platform, we create unique, responsive, and mobile-friendly websites that captivate users.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Web Development</strong><br/> Using the latest technologies, we build high-performing websites that are secure, scalable, and easy to manage. Our development process includes custom coding, CMS integration (WordPress, Shopify, etc.), and e-commerce solutions.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>UI/UX Design</strong><br/> We focus on creating user-centric interfaces that enhance navigation and engagement, ensuring that visitors stay longer and convert into customers.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>E-Commerce Solutions</strong><br/> We develop e-commerce websites that provide seamless shopping experiences. Our solutions include payment gateway integration, inventory management, and user-friendly product pages that boost sales.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Website Maintenance & Support</strong><br/> Our job doesn’t end with development. We offer ongoing maintenance, updates, and support to ensure your website runs smoothly and stays up-to-date with the latest industry standards.</li>          
          </ul>
          <a href="/website-design-development"  className='mr-auto text-red-400 border border-red-400 px-5 py-2 mt-5 font-base font-semibold rounded-[25px] hover:bg-red-400 hover:text-white text-lg noto-serif-bold'>Read More</a>
      </div>
      
    </div>

    <div className="flex flex-wrap sm:-m-4 -mx-4 mb-10 lg:mb-20 -mt-4 md:space-y-0 space-y-6">
      <div className="p-4 lg:w-1/2 flex flex-col text-left items-center justify-start">
          <h1 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">Digital Marketing</h1>
          <p className="leading-relaxed text-base text-left mb-[20px]">Building a website and having great visuals is just the beginning. Our <strong>digital marketing services</strong> are designed to increase your brand’s visibility, drive traffic, and turn leads into loyal customers through data-driven strategies.</p>
          <ul>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Search Engine Optimization (SEO)</strong><br/> Our SEO experts optimize your website to rank higher on search engines like Google, ensuring that potential customers find you easily when searching for products or services..</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Pay-Per-Click (PPC) Advertising</strong><br/> Reach your target audience instantly through paid search and social media campaigns. We manage Google Ads, Bing Ads, and paid social campaigns that deliver a strong return on investment (ROI).</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Social Media Marketing</strong><br/> Build meaningful connections with your audience through targeted social media strategies. We create engaging content, manage your social profiles, and develop campaigns on platforms like Instagram, Facebook, LinkedIn, and Twitter to grow your brand.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Content Marketing</strong><br/> Great content is the key to building authority and trust. Our content marketing services include creating blogs, articles, videos, and infographics that position your business as a leader in your industry.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Email Marketing</strong><br/> Keep your audience engaged with personalized email campaigns that nurture leads, promote products, and foster customer loyalty. From automated workflows to email newsletters, we handle every aspect of your email strategy.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Conversion Rate Optimization (CRO)</strong><br/> We analyze user behavior on your site to identify opportunities for improving conversions. Our CRO strategies optimize your landing pages, calls to action, and user journey to turn visitors into paying customers.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Influencer Marketing</strong><br/> We connect your brand with the right influencers to help you reach a wider audience, build credibility, and drive engagement through authentic, influencer-driven campaigns.</li>          
          </ul>
          <a href="/digital-marketing-service"  className='mr-auto text-red-400 border border-red-400 px-5 py-2 mt-5 font-base font-semibold rounded-[25px] hover:bg-red-400 hover:text-white text-lg noto-serif-bold'>Read More</a>
      </div>
      <div className="p-4 lg:w-1/2 flex flex-col text-center items-center">
      <img src={`${process.env.PUBLIC_URL}/digital-marketing-banner.jpg`} width={600} height={600} className='w-full h-full object-cover' title="Techespark Digital Marketing Service" />
      </div>
    </div>

    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
      <div className="p-4 lg:w-1/2 flex flex-col text-center items-center order-1 lg:order-none">
      <img src={`${process.env.PUBLIC_URL}/why-choose-us.jpg`} width={600} height={600} className='w-full h-full object-cover' title="Techespark Why Choose Us"/>
        
      </div>
      <div className="p-4 lg:w-1/2 flex flex-col text-left items-center justify-start">
          <h1 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">Why Choose Us?</h1>
          <ul>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Comprehensive Solutions</strong> From creative design and web development to advanced digital marketing strategies, we offer everything you need to establish a strong and successful online presence.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Tailored Strategies</strong><br/> We know that every business is unique. That’s why we create personalized strategies that align with your goals, industry, and target audience.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Expert Team</strong><br/> Our team of designers, developers, and marketers brings years of experience and a passion for helping businesses succeed in the digital landscape.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Results-Oriented Approach</strong><br/> We focus on delivering measurable results that drive growth, whether it's through increased traffic, higher engagement, or boosted sales.</li>
          </ul>
          
      </div>
      
    </div>
    <p className="leading-relaxed text-base text-left mb-[20px] mt-10">At Techespark, we are dedicated to helping you navigate the digital world with confidence and success. Whether you need eye-catching designs, a powerful website, or data-driven marketing campaigns, we’re here to help you build a brand that stands out and thrives online.</p>
  </div>
</section>
</>

  );
};

export default Services;