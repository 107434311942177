import React from 'react';
import { LuMapPin } from "react-icons/lu";
import { MdOutlineEmail } from "react-icons/md";
import { IoIosCall } from "react-icons/io";
const Footer = () => {
  return (

<footer className="text-gray-400 body-font bg-[#333]">
  <div className="container px-5 py-10 lg:py-24 mx-auto flex lg:items-start md:flex-nowrap flex-wrap flex-col lg:flex-row">
    <div className="w-full lg:w-1/4 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
      <a className="flex title-font font-medium items-center md:justify-start text-gray-900">
        <img src={`${process.env.PUBLIC_URL}/tech-2-spark-white.png`} width={200} height={80} className='w-[250px] h-auto mb-5' alt='Techespark Logo'/>
      </a>
      <p className="mt-2 text-md text-gray-400 text-left">At Techespark, we are passionate about transforming businesses through creative design, cutting-edge web development, and powerful digital marketing strategies. We are a full-service digital agency, dedicated to helping brands establish a dynamic online presence and drive measurable results.</p>
    </div>
    <div className="flex-grow flex flex-wrap lg:pl-10 -mb-10 mt-10 lg:mt-0 text-left lg:text-center w-full lg:w-3/4">
      <div className="lg:w-1/3 md:w-1/2 w-full px-0 lg:px-4 text-left">
        <h2 className="title-font font-bold text-red-400 tracking-widest text-lg mb-3 uppercase noto-serif-bold">Information</h2>
        <ul className="list-none mb-10 leading-[30px] ">
          <li>
            <a href='/' className="text-gray-400 hover:text-red-400">Home</a>
          </li>
          <li>
            <a href="/about" className="text-gray-400 hover:text-red-400">About Us</a>
          </li>
          <li>
            <a href="/portfolio" className="text-gray-400 hover:text-red-400">Portfolio</a>
          </li>
          <li>
            <a href="/services" className="text-gray-400 hover:text-red-400">Services</a>
          </li>
        </ul>
      </div>
      <div className="w-full lg:w-1/3 px-0 lg:px-4 text-left">
        <h2 className="title-font font-bold text-red-400 tracking-widest text-lg mb-3 uppercase noto-serif-bold">What We Do</h2>
        <ul className="list-none mb-10 leading-[30px]">
          <li>
            <a href='/services/graphic-design-service' className="text-gray-400 hover:text-red-400">Graphic Design</a>
          </li>
          <li>
            <a href='/services/website-design-development' className="text-gray-400 hover:text-red-400">Website Design</a>
          </li>
          <li>
            <a href='/services/digital-marketing-service' className="text-gray-400 hover:text-red-400">Digital Marketing</a>
          </li>
          <li>
            <a href='/services/ecommerce-development-service' className="text-gray-400 hover:text-red-400">Websites Development</a>
          </li>
        </ul>
      </div>
      <div className="lg:w-1/3 md:w-1/2 w-full px-0 lg:px-4 text-left">
        <h2 className="title-font font-bold text-red-400 tracking-widest text-lg mb-3 uppercase noto-serif-bold">Contact Us</h2>
        <ul className="list-none mb-10 ">
          <li>
              <div className='flex items-start gap-2 mb-3'><span className='text-red-400 mt-1'><LuMapPin size={20}/></span> <span>H.No. 1-3-50, OmInfra Appartment, F.No. 203, New Maruthi Nagar, Kothapet, Hyderabad, Telangana - 500035</span></div>
          </li>
          <li>
              <div className='flex items-center gap-2 mb-3'><span className='text-red-400'><MdOutlineEmail size={20}/></span> <span>info@techespark.com</span></div>
          </li>
          <li>
            <div className='flex items-center gap-2'><span className='text-red-400'><IoIosCall size={20}/></span> <span>(+91) 99597 90204</span></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div className="bg-[#333] border-t border-gray-600">
    <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
      <p className="text-gray-400 text-sm text-center sm:text-left">© 2024 Techespark — All Rights Reserved.
      </p>
      <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
        <a href='https://www.facebook.com/people/techespark/61565947036491/' target='_blank' className="text-red-400">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a href="https://x.com/Techespark" target='_blank' className="ml-3 text-red-400">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a href='https://www.linkedin.com/company/techespark' target='_blank' className="ml-3 text-red-400">
          <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" className="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
  </div>
</footer>
  );
};

export default Footer;