import React from 'react';
const PortfolioHome = () => {
  return (
    <section className="text-gray-600 body-font">
  <div className="container px-5 py-10 lg:py-24 mx-auto">
    <div className="flex flex-wrap w-full mb-5 lg:mb-20">
      <div className="w-full mb-6 flex flex-col justify-center items-center lg:mb-0">
        <h1 className="text-3xl lg:text-4xl text-center title-font mb-2 text-gray-500 noto-serif-bold">Our Portfolio</h1>
        <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">At Techespark, we take pride in delivering creative, functional, and results-driven solutions for our clients. Our portfolio showcases a diverse range of projects that reflect our expertise in graphic design, website development, and digital marketing. Each project is a testament to our commitment to quality, innovation, and client satisfaction.</p> 
        <div className="h-1 w-20 bg-red-400 rounded my-4"></div>
      </div>

    </div>
    <div className="flex flex-wrap -m-4">
      <div className="xl:w-1/2 md:w-1/2 p-2">
        <div className="bg-[#f7f7f7] p-3 rounded-lg overflow-hidden group relative">
          <img className="rounded w-full object-cover object-center" src={`${process.env.PUBLIC_URL}/allenhomoeopathy.png`} alt="content" class='transition-all ease-in-out group-hover:scale-110'/>
          <div className='w-full h-full bg-white/90 absolute left-0 top-0 flex-col items-center justify-center text-center hidden group-hover:flex px-10  group-hover:border overflow-hidden group-hover:border-red-400 rounded-lg'>
            <h3 className='title-font font-bold text-xl mb-2 text-red-400 noto-serif-bold'>Allen Homoeopathy</h3>
            <p>Allenhomoeopathy is most leading company in india. we are selling homoepathy products in e-commerce portal.</p>
            <a href='https://www.allenhomoeopathy.com/' target='_blank' className="mt-5 text-red-400 inline-flex font-bold justify-center self-center items-center border-[2px] border-red-400 p-3 px-8 rounded-[25px] group-hover:bg-red-400 group-hover:text-white transition-all ease-in-out noto-serif-bold">View More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
          </div>
        </div>
      </div>
      <div className="xl:w-1/2 md:w-1/2 p-2">
        <div className="bg-[#f7f7f7] p-3 rounded-lg overflow-hidden group relative">
          <img className="rounded w-full object-cover object-center" src={`${process.env.PUBLIC_URL}/akshayanidhimart.png`} alt="content" class='transition-all ease-in-out group-hover:scale-110' />
          <div className='w-full h-full bg-white/90 absolute left-0 top-0 flex-col items-center justify-center text-center hidden group-hover:flex px-10 group-hover:border overflow-hidden group-hover:border-red-400 rounded-lg'>
            <h3 className='title-font font-bold text-xl mb-2 text-red-400 noto-serif-bold'>Akshayanidhi Mart</h3>
            <p>Akshayanidhimart is all type of products selling in e-commerce portal in all over india.</p>
            <a href='https://akshayanidhimart.com/' target='_blank' className="mt-5 text-red-400 inline-flex font-bold justify-center self-center items-center border-[2px] border-red-400 p-3 px-8 rounded-[25px] group-hover:bg-red-400 group-hover:text-white transition-all ease-in-out noto-serif-bold">View More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
          </div>
        </div>
      </div>
      <div className="xl:w-1/2 md:w-1/2 p-2">
        <div className="bg-[#f7f7f7] p-3 rounded-lg overflow-hidden group relative">
          <img className="rounded w-full object-cover object-center" src={`${process.env.PUBLIC_URL}/utsavsaanvi.png`} alt="content" class='transition-all ease-in-out group-hover:scale-110'/>
          <div className='w-full h-full bg-white/90 absolute left-0 top-0 flex-col items-center justify-center text-center hidden group-hover:flex px-10 group-hover:border overflow-hidden group-hover:border-red-400 rounded-lg'>
            <h3 className='title-font font-bold text-xl mb-2 text-red-400 noto-serif-bold'>Ustavsaavi</h3>
            <p>Ustavsaanvi is most leading logistic company in india.</p>
            <a href='http://www.utsavsaanvi.com/' target='_blank' className="mt-5 text-red-400 inline-flex font-bold justify-center self-center items-center border-[2px] border-red-400 p-3 px-8 rounded-[25px] group-hover:bg-red-400 group-hover:text-white transition-all ease-in-out noto-serif-bold">View More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
          </div>
        </div>
      </div>
      <div className="xl:w-1/2 md:w-1/2 p-2">
        <div className="bg-[#f7f7f7] p-3 rounded-lg overflow-hidden group relative">
          <img className="rounded w-full object-cover object-center" src={`${process.env.PUBLIC_URL}/tech-spot-academy.png`} alt="content" class='transition-all ease-in-out group-hover:scale-110'/>
          <div className='w-full h-full bg-white/90 absolute left-0 top-0 flex-col items-center justify-center text-center hidden group-hover:flex px-10 group-hover:border overflow-hidden group-hover:border-red-400 rounded-lg'>
            <h3 className='title-font font-bold text-xl mb-2 text-red-400 noto-serif-bold'>Tech Spot Academy</h3>
            <p>Techspot Academy is currently growing educational institution in india.</p>
            <a href='https://www.techspotacademy.com/' target='_blank' className="mt-5 text-red-400 inline-flex font-bold justify-center self-center items-center border-[2px] border-red-400 p-3 px-8 rounded-[25px] group-hover:bg-red-400 group-hover:text-white transition-all ease-in-out noto-serif-bold">View More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
          </div>
        </div>
      </div>
    </div>
    <a href='/portfolio' className="mt-10 text-red-400 inline-flex font-bold   justify-start self-start items-center border-[2px] border-red-400 p-3 px-8 rounded-[25px] hover:bg-red-400  hover:text-white transition-all ease-in-out noto-serif-bold">Read More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
  </div>
  </section>
  );
};

export default PortfolioHome;