import React, { useState } from 'react';
import RequestQuoteForm from '../RequestAQuote/RequestQuote';
import { IoCloseSharp } from "react-icons/io5";
const Hero = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
return (
<>
<section className="text-gray-600 body-font bg-[url('../public/team-banner.jpg')] bg-no-repeat relative bg-cover before:content-[''] before:w-full before:h-full before:bg-black before:bg-opacity-20 before:absolute before:top-0 before:left-0">
  <div className="container mx-auto flex flex-row flex-wrap px-4 lg:px-0 py-10 lg:py-24 md:flex-row items-center justify-between gap-4 gap-y-6 relative">
    <div className="w-full lg:w-[calc(50%-10px)] flex flex-col md:items-start md:text-left mb-1 lg:mb-0 items-center text-center h-auto lg:h-[320px] bg-white justify-center px-[7%] rounded-[10px] group py-6 lg:py-0">
      <h1 className='w-auto table title-font text-red-400 font-bold text-3xl mx-auto text-center transition-all ease-in-out group-hover:scale-110'>We are building Brands with Impactful Designs</h1>
      <p className='text-black-600 mt-[10px] text-center'>We craft visually compelling brand identities and design websites that drive engagement and growth.</p>
      <button type='button' onClick={togglePopup} className='mx-auto text-red-400 border border-red-400 px-6 py-2 mt-5 font-base font-semibold rounded-[25px] hover:bg-red-400 hover:text-white noto-serif-bold'>Request a Quote</button>
    </div>
    <div className="w-full lg:w-[calc(50%-10px)] h-auto lg:h-[320px] bg-white flex flex-col items-center justify-center px-[5%] rounded-[10px] group py-6 lg:py-0">
        <h1 className='w-auto table title-font text-red-400 font-bold text-3xl mx-auto transition-all ease-in-out group-hover:scale-110'>Your Online Store, Built for Growth</h1>
        <p className='text-black-600 mt-[10px] text-center'>Custom e-commerce solutions that drive sales, improve user experience, and scale with your business.</p>
        <button type='button' onClick={togglePopup} className='mx-auto text-red-400 border border-red-400 px-6 py-2 mt-5 font-base font-semibold rounded-[25px] hover:bg-red-400 hover:text-white noto-serif-bold'>Request a Quote</button>
        </div>
    <div className="w-full lg:w-[calc(33.33%-14px)] h-auto lg:h-[320px] bg-white flex items-center flex-col justify-center px-10 rounded-[10px] group py-6 lg:py-0">
      <h1 className='w-auto table title-font text-red-400 font-bold text-3xl mx-auto transition-all ease-in-out group-hover:scale-110'>Fueling Growth Through Strategic Digital Marketing</h1>
      <p className='text-black-600 mt-[10px] text-center'>From SEO to social media, we create tailored strategies that drive traffic, engagement, and conversions.</p>
      <button type='button' onClick={togglePopup} className='mx-auto text-red-400 border border-red-400 px-6 py-2 mt-5 font-base font-semibold rounded-[25px] hover:bg-red-400 hover:text-white noto-serif-bold'>Request a Quote</button>
    </div>
    <div className="w-full lg:w-[calc(33.33%-14px)] h-auto lg:h-[320px] bg-white flex items-center justify-center flex-col px-10 rounded-[10px] group py-6 lg:py-0">
      <h1 className='w-auto table title-font text-red-400 font-bold text-3xl mx-auto transition-all ease-in-out group-hover:scale-110'>Transform Your Business with Innovative Solutions</h1>
      <p className='text-black-600 mt-[10px] text-center'>Custom digital tools designed to streamline operations, improve productivity, and drive success.</p>
      <button type='button' onClick={togglePopup} className='mx-auto text-red-400 border border-red-400 px-6 py-2 mt-5 font-base font-semibold rounded-[25px] hover:bg-red-400 hover:text-white noto-serif-bold'>Request a Quote</button>
    </div>
    <div className="w-full lg:w-[calc(33.33%-14px)] h-auto lg:h-[320px] bg-white flex items-center justify-center px-10 flex-col rounded-[10px] group py-6 lg:py-0">
      <h1 className='w-auto table title-font text-red-400 font-bold text-2xl mx-auto transition-all ease-in-out group-hover:scale-110'>Creative Designs, Stunning Websites, & Digital Marketing That Drives Results</h1>
      <p className='text-black-600 mt-[10px] text-center'>Your one-stop solution for professional graphic design, website creation, and marketing strategies that boost your online presence.</p>    
      <button type='button' onClick={togglePopup} className='mx-auto text-red-400 border border-red-400 px-6 py-2 mt-5 font-base font-semibold rounded-[25px] hover:bg-red-400 hover:text-white noto-serif-bold'>Request a Quote</button>
      </div>
  </div>
</section>
{isPopupVisible && (
  <div className='w-full h-full fixed bg-black/80 left-0 top-0 flex items-center justify-center z-[999]'>
    <div className='w-[500px] max-w-[95%] bg-white rounded-lg'>
      <div className='px-10 py-5 border-b border-gray-200 flex items-center justify-between'>
      <h2 className='text-red-400 text-2xl title-font font-bold text-left noto-serif-bold w-full'>Request a Quote</h2>
        <button onClick={togglePopup} className='text-black/60'><IoCloseSharp size={20}/></button>
      </div>
      <div className='py-10 px-10'>
        <RequestQuoteForm />
      </div>
    </div>
  </div>
)}
</>
);
};

export default Hero;