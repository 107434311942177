import React from 'react';
import { Helmet } from 'react-helmet';
const About = () => {
  return (
<>
<Helmet>
        <title>Techespark About - Expert Graphic Design, Web Development & Digital Marketing Services | Techespark
        </title>
        <meta name="description" content="Learn about Techespark, a leading provider of graphic design, website development, and digital marketing services. Our team of experts helps businesses grow their online presence and brand identity. Contact us today to see how we can help you succeed!
" />
        <meta name="keywords" content="Learn about Techespark, a leading provider of graphic design, website development, and digital marketing services. Our team of experts helps businesses grow their online presence and brand identity. Contact us today to see how we can help you succeed!
" />
      </Helmet>
<section className="text-gray-600 manrope-regular">
<div className="text-gray-600 body-font relative h-[200px] lg:h-[300px] overflow-hidden bg-cover before:content-[''] before:w-full before:h-full before:bg-black before:bg-opacity-70 before:absolute before:top-0 before:left-0">
  <img src={`${process.env.PUBLIC_URL}/about-us-banner.jpg`} width={600} height={600} className='w-full h-full object-cover ' title='about us banner' />
  <div className="container px-5 py-24 mx-auto absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
    <h1 className='text-4xl lg:text-5xl font-bold title-font text-white mb-4 noto-serif-bold table relative mx-auto before:content-[""] before:w-[70px] before:h-[4px] before:bg-red-400 before:absolute before:bottom-[-30px] before:left-[50%] before:translate-x-[-50%]'>About Us</h1>
  </div>
</div>
  <div className="container px-5 py-6 lg:py-24 mx-auto">
    <div className="text-center mb-10 lg:mb-20">
      <p className="text-base leading-relaxed text-left text-black-600 mb-4">At Techespark, we are passionate about transforming businesses through creative design, cutting-edge web development, and powerful digital marketing strategies. We are a full-service digital agency, dedicated to helping brands establish a dynamic online presence and drive measurable results.</p>
      <p className="text-base leading-relaxed text-left text-black-600">Founded on the belief that creativity, technology, and strategy should work hand-in-hand, we offer a comprehensive range of services, from graphic design that elevates your brand identity to website development that provides seamless user experiences. Whether you're a startup looking to make your mark or an established business aiming to expand your reach, we deliver customized solutions that align with your goals and resonate with your audience.</p>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 lg:space-y-4 lg:pb-10">
      <div className="p-4 md:w-1/2 flex flex-col text-left items-center">
        <div className="w-full">
          <h1 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold">Our Expertise</h1>
          <p className="leading-relaxed text-base text-left mb-[20px]"><strong>Graphic Design :</strong><br/> We bring your brand to life through captivating visuals and compelling marketing materials. From logo design and brand identity creation to social media graphics and promotional content, our design solutions are crafted to connect with your audience and set you apart from the competition.
          </p>
          <p className="leading-relaxed text-base text-left mb-[20px]"><strong>Website Design & Development :</strong><br/> We create websites that not only look stunning but also function flawlessly. Our designs are user-centric, responsive, and optimized for performance, ensuring that your digital storefront provides an exceptional experience across all devices. Whether you need a custom website or an e-commerce platform, we build solutions tailored to your business needs.</p>
          <p className="leading-relaxed text-base text-left mb-[20px]"><strong>Digital Marketing :</strong><br/> In today’s fast-paced digital world, being found online is crucial. Our digital marketing services, including SEO, social media marketing, content creation, and PPC advertising, are designed to increase your brand’s visibility, engage your audience, and drive conversions. We use data-driven strategies to ensure your marketing efforts deliver real, measurable results.
          </p>
        </div>
      </div>
      <div className="p-4 md:w-1/2 flex flex-col text-left items-center">
        <div className="w-full">
          <h1 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold">Why We Stand Out</h1>
          <p className="leading-relaxed text-base text-left mb-[20px]"><strong>Personalized Approach :</strong><br/> We know that no two businesses are the same, which is why we take the time to understand your unique challenges and opportunities. Our team collaborates closely with you to develop tailored strategies that align with your vision and objectives.</p>
          <p className="leading-relaxed text-base text-left mb-[20px]"><strong>End-to-End Solutions :</strong><br/> From the initial spark of creativity to full-scale digital marketing campaigns, we offer everything under one roof. Whether you need a brand refresh, a website overhaul, or a digital marketing boost, we are here to guide you every step of the way.</p>
          <p className="leading-relaxed text-base text-left mb-[20px]"><strong>Results-Oriented :</strong><br/> We believe in the power of data and creativity combined. Our focus is on delivering designs that inspire, websites that perform, and campaigns that drive tangible growth for your business.</p>
          <p className="leading-relaxed text-base text-left mb-[20px]"><strong>A Team You Can Trust :</strong><br/> With years of experience in the industry, our team of designers, developers, and marketers brings a wealth of knowledge and passion to every project. We are committed to helping you succeed, and your success is our success.</p>
            </div>
      </div>
    </div>
    <p className="leading-relaxed text-base text-left mb-[20px]">At Techespark, we don’t just create websites or run marketing campaigns—we build digital experiences that connect, engage, and convert. Let us help you unlock the full potential of your brand and achieve success in the ever-evolving digital landscape.</p>
  </div>


</section>
</>
);
};

export default About;