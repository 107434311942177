import React from 'react';
import { MdOutlineDesignServices } from "react-icons/md";
import { IoCodeSlash } from "react-icons/io5";
import { IoRocketOutline } from "react-icons/io5";
import { Helmet } from 'react-helmet';
const WebsiteDesign = () => {
  return (
    <>
    <Helmet>
        <title>Website Design & Development - Custom Website Design & Development | Responsive, SEO-Friendly Websites</title>
        <meta name="description" content="Get a custom website designed to convert visitors into customers. We specialize in responsive and SEO-optimized websites that look great on all devices and drive results. Elevate your online presence today!" />
        <meta name="keywords" content="website design, web development, responsive website, custom website design, SEO-friendly website, web development services, UX/UI design, website redesign" />
      </Helmet>
      <section className="text-gray-600 body-font">
    <div className="text-gray-600 body-font relative h-[200px] lg:h-[300px] overflow-hidden bg-cover before:content-[''] before:w-full before:h-full before:bg-black before:bg-opacity-70 before:absolute before:top-0 before:left-0">
  <img src={`${process.env.PUBLIC_URL}/about-us-banner.jpg`} width={600} height={600} className='w-full h-full object-cover ' title='about us banner' />
  <div className="container px-5 py-24 mx-auto absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
    <h1 className='text-4xl lg:text-5xl font-bold title-font text-white mb-4 noto-serif-bold  table relative mx-auto before:content-[""] before:w-[70px] before:h-[4px] before:bg-red-400 before:absolute before:bottom-[-30px] before:left-[50%] before:translate-x-[-50%]'>Website Design & Development</h1>
  </div>
</div>
  <div className="container px-5 py-6 lg:py-24 mx-auto">
    <div className="text-center mb-10 lg:mb-20">
      <p className="text-base leading-relaxed mx-auto text-black-500 text-left">At Techespark, we believe that a website is more than just an online presence—it's a powerful tool that communicates your brand, engages your audience, and drives business success. Our website design services are crafted to create visually stunning, user-friendly, and fully responsive websites that make a lasting impression on your visitors.</p>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mt-4 md:space-y-0 space-y-6  lg:mb-20">
    <div className="p-4 w-full flex flex-col text-center items-center">
    <img src={`${process.env.PUBLIC_URL}/web-design-banner.jpg`} width={600} height={600} className='w-full h-full object-cover ' title="Techespark Web Design Service" />
      </div>
      
    </div>
    
    <div className="flex flex-wrap sm:-m-4 -mx-4 mb-10 lg:mb-20 -mt-4 md:space-y-0 space-y-6">
      <div className="p-4 lg:w-1/2 flex flex-col text-center items-center lg:order-none">
      <h2 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">What We Offer</h2>
          <ul>
              <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Custom Design Tailored to Your Brand :</strong> We don’t believe in one-size-fits-all. Our design team works closely with you to understand your brand’s unique personality and business goals. The result? A website that is tailored to your vision and reflects your brand identity.</li>
              <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Responsive and Mobile-Friendly Designs :</strong> With the majority of users browsing on mobile devices, we ensure that your website looks and functions flawlessly across all screen sizes, providing an optimal user experience whether on a desktop, tablet, or smartphone.</li>
              <li className="leading-relaxed text-base text-left mb-[20px]"><strong>User-Centered Design :</strong> Our design process is centered on the user experience (UX). We focus on intuitive navigation, clean layouts, and engaging visuals, ensuring your visitors can easily find what they’re looking for and are encouraged to take action.</li>
              <li className="leading-relaxed text-base text-left mb-[20px]"><strong>SEO-Optimized for Better Visibility :</strong> A great design is only effective if your target audience can find it. That’s why we integrate SEO best practices into every website, optimizing for speed, performance, and search engine rankings.</li>
              <li className="leading-relaxed text-base text-left mb-[20px]"><strong>E-Commerce Solutions :</strong> Whether you’re launching an online store or upgrading an existing one, our e-commerce design services focus on seamless shopping experiences, secure payment integrations, and high-performing product pages that convert visitors into customers.</li>
              <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Website Maintenance & Support :</strong> A website needs ongoing updates and support to perform at its best. We offer maintenance services to ensure your site remains secure, up-to-date, and free from any issues that could impact user experience or performance.</li>
          </ul>
      </div>
      <div className="p-4 lg:w-1/2 flex flex-col text-left items-center justify-start">
      <h2 className="text-red-400 text-2xl title-font font-bold mb-5 text-left noto-serif-bold w-full">Our Process</h2>
      <ul>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Discovery & Planning :</strong> We begin by understanding your business, your target audience, and your goals for the website.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Design & Development :</strong> We craft wireframes and mockups, collaborating with you for feedback, and then move into development using the latest technologies.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Testing & Launch :</strong> Before going live, we rigorously test the website for performance, speed, and functionality to ensure a flawless user experience.</li>
          <li className="leading-relaxed text-base text-left mb-[20px]"><strong>Ongoing Optimization :</strong> After launch, we offer continual support to optimize, update, and grow your website as your business evolves.</li>        
          </ul>
      </div>
      
    </div>

    <p className="leading-relaxed text-base text-left mb-[20px] mt-10">Whether you’re starting from scratch or looking to refresh your existing site, Techespark is here to deliver creative, strategic, and effective web design solutions that make an impact.</p>
  </div>
</section>
    </>

  );
};

export default WebsiteDesign;