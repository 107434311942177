// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css'; // Import the Tailwind CSS file
import App from './App';
import Header from './components/layout/header/Header';
import Footer from './components/layout/Footer/footer';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  gtmId: 'GTM-5RP5H4W4', // Replace with your GTM Container ID
};

TagManager.initialize(tagManagerArgs);
ReactDOM.render(
  <BrowserRouter>
  <Header />
  <App />
  <Footer/>
  </BrowserRouter>,
  document.getElementById('root')
);
